<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="flex-wrap" style="gap: 8px;">
        <NavButton />
        <h6 class="text-h6 font-weight-bold text-uppercase">Perfil proveedor - Agregar Accionistas y representante legal</h6>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <AgregarAccionistaV2Component v-if="haveRole('ROLE_PROVEEDOR')" />

        <v-card class="mt-4">
          <v-card-title>
            <h6 class="text-subtitle-2 font-weight-bold text-uppercase">
              <v-icon class="mr-1">mdi-account-group-outline</v-icon>
              Lista de accionistas
            </h6>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <TablaAccionistas />
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import TablaAccionistas from "./components/TablaAccionistasComponent.vue";
import AgregarAccionistaV2Component from "./components/AgregarAccionistaV2Component.vue";
import NavButton from "@/components/utils/NavButton.vue";

export default {
  name: "agregarAccionistasAsociadosView",
  components: {
    AgregarAccionistaV2Component,
    TablaAccionistas,
    NavButton,
  },
  data: () => ({}),
  methods: {
    regresar() {
      this.$router.back();
      // Navegar a la ruta anterior
    },
  },
};
</script>
<style lang=""></style>
